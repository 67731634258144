<template
  ><span>
    <div v-for="(item, index) in items" :key="index" class="dl">
      <div class="dt">
        <div class="input-checkbox">
          <input
            :id="item.id"
            v-model="data.values"
            :name="item.id"
            type="checkbox"
            :value="item.id"
          />
          <span class="checkmark"></span>
        </div>
      </div>
      <div class="dd">
        <label :for="item.id">{{ item.text }}</label>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "Checkbox",
  data: () => {
    return {
      data: {
        values: []
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    getAnswer() {
      let answer = [];
      for (let i = 0; i < this.data.values.length; i++) {
        answer.push({
          questionId: this.questionId,
          optionValue: true,
          optionId: this.data.values[i]
        });
      }
      return answer;
    }
  }
};
</script>

<style scoped></style>
